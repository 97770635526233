import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuickSearch from "components/ui/Button";
import RentalAppraisal from "components/sandy/RentalAppraisal";
import Translate from "components/sandy/Translate";
import DemographicsArea from "components/sandy/DemographicsArea";
import FeatureExpert from "components/sandy/FeatureExpert";

const Sandy = () => {
  const [typewriterText, setTypewriterText] = useState("");
  const [selectedBox, setSelectedBox] = useState("");
  const [isRentalAppraisal, setIsRentalAppraisal] = useState(false);
  const tabRefs = useRef([]); // Array of references for each tab

  const typewriterMessage = "What can I help you with?";

  const boxes = [
    {
      title: "Rental Appraisal",
      text: "create a rental appraisal letter",
      type: "component",
    },
    {
      title: "Translate",
      text: "translate to any language",
      type: "component",
    },
    {
      title: "Demographics of area",
      text: "search through the demographics of the area",
      type: "component",
    },
    {
      title: "Property feature expert",
      text: "take a photo, become an expert in seconds",
      type: "component",
    },
  ];

  const componentsMap = {
    "Rental Appraisal": RentalAppraisal,
    Translate: Translate,
    "Demographics of area": DemographicsArea,
    "Property feature expert": FeatureExpert,
  };

  const currentComponent =
    selectedBox &&
    boxes.find((box) => box.title === selectedBox)?.type === "component"
      ? componentsMap[selectedBox]
      : null;

  const handleBoxClick = (box, index) => {
    setSelectedBox(box.title);
    if (box.type === "link") {
      window.location.href = componentsMap[box.title];
    } else {
      tabRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center", // Ensures it gets centered in the view
        inline: "center",
      });
    }
  };

  const isLink = (boxTitle) => {
    const box = boxes.find((box) => box.title === boxTitle);
    return box?.type === "link";
  };

  const typewriterEffect = (text, index = 0) => {
    if (index === 0) {
      setTypewriterText(""); // Clear previous text when starting
    }

    if (index < text.length) {
      setTypewriterText((prev) => prev + text.charAt(index));
      setTimeout(() => {
        typewriterEffect(text, index + 1);
      }, 50); // Adjust the speed by changing the delay time
    }
  };

  useEffect(() => {
    typewriterEffect(typewriterMessage);
  }, []);

  const handleRentalAppraisalStatus = (status) => {
    setIsRentalAppraisal(status);
  };

  return (
    <div className="container">
      <ToastContainer />

      {!currentComponent && (
        <p className="max-w-4xl mx-auto text-start w-full">{typewriterText}</p>
      )}

      <div className="pb-28">
        {currentComponent &&
          !isLink(selectedBox) &&
          React.createElement(currentComponent, {
            isRentalAppraisal: handleRentalAppraisalStatus, // Pass the callback
          })}
      </div>
      
      <div
        className={`z-10 w-full fixed left-0 right-0 bg-white px-6 flex items-center justify-center ${
          !isRentalAppraisal && selectedBox === "Rental Appraisal"
            ? "bottom-20 py-2"
            : "bottom-0 pb-12 pt-2"
        }`}
      >
        <div className="overflow-x-auto whitespace-nowrap box-scrollbar scroll-smooth">
          {boxes.map((box, index) => (
            <div
              key={index}
              ref={(el) => (tabRefs.current[index] = el)} // Assign a ref to each tab
              className={`bg-lightgray rounded-xl flex-shrink-0 inline-flex flex-col items-center hover:bg-mediumgray cursor-pointer mr-4 py-2.5 px-6 ${
                box.title === selectedBox ? "bg-mediumgray" : ""
              }`}
              onClick={() => handleBoxClick(box, index)} // Pass index to handle scroll
            >
              <div className="text-start text-xs relative">
                <h6>{box.title}</h6>
                <span className=" text-darkergray">{box.text}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sandy;
