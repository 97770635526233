import React, { useEffect, useRef } from "react";

const Modal = ({ isOpen, onClose, title, children,className="max-w-md"  }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="custom-modal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 m-0"
      style={{ zIndex: 1010,margin:"0 !important" }}
    >
      <div
        ref={modalRef}
        className={`bg-white rounded p-6 w-full ${className} max-h-[400px] overflow-y-auto relative`}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-darkergray"
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
        {title && <h4 className="text-center">{title}</h4>}
        <div className="mt-4 space-y-4">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
