import React, { useState, useEffect } from "react";
import ausrealtyLogo from "assets/images/ausrealy-logo.svg";
import axios from "utils/axios";
import Error from "../../components/ui/Error";
import PageLoader from "../../components/ui/PageLoader";

const PreList = () => {
  const [booking, setBooking] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  // Extract the UUID from the URL
  const getUUIDFromURL = () => {
    const url = window.location.pathname;
    const uuid = url.split("/prelist/")[1]; // Assuming the UUID comes after '/prelist/'
    return uuid;
  };

  // Fetch booking data by UUID
  const fetchBookingByUUID = async (uuid) => {
    try {
      const response = await axios.get(`/bookings/prelist/${uuid}`);
      setBooking(response.data.data); // Assuming the booking data is in 'data'
    } catch (err) {
      console.error("Error fetching booking data:", err);
      setError(true);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  // useEffect to fetch booking when component mounts
  useEffect(() => {
    const uuid = getUUIDFromURL();
    if (uuid) {
      fetchBookingByUUID(uuid); // Fetch booking by UUID
    }
  }, []);

  // Handling loading state
  if (loading) {
    return <PageLoader />;
  }

  // Handling error state
  if (error) {
    return <Error />;
  }

  // Media handling based on booking properties
  const getMediaForProperty = () => {
    const { property } = booking;

    // Water Views media
    if (property.waterViews !== "No") {
      return {
        images: [
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/waterfront/Web/8V8_2873+(1).jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/waterfront/Web/AM__8101+(2)+-+Copy.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/waterfront/Web/DJI_20240913164726_0513_D-Edit-Edit-2+-+Copy.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/waterfront/Web/Downloaded-original51171644-DroneDusk3b.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/waterfront/Web/Still+11.jpg",
        ],
        video:
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/waterfront/Video/104+Moons-final-ok.mp4",
      };
    }

    // Development Potential media
    if (property.developmentPotential) {
      return {
        images: [
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/development-site/Web/8V8_0432.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/development-site/Web/8V8_0437.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/development-site/Web/8V8_0440.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/development-site/Web/8V8_0444.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/development-site/Web/8V8_0447.jpg",
        ],
        video: null, // No video mentioned for development potential
      };
    }

    // House media
    if (property.propertyType === "House") {
      return {
        images: [
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/house/Web/Downloaded-webres49602064-CurtainBlowing.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/house/Web/Downloaded-webres49602065-Details.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/house/Web/Downloaded-webres49602067-DronePoolTopDown.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/house/Web/Downloaded-webres49602068-DronePoolWide.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/house/Web/Downloaded-webres49602119-FrontTight.jpg",
        ],
        video:
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/house/Video/1_glenview_st_kogarah_bay+(1080p).mp4",
      };
    }

    // ApartmentUnitFlat media
    if (property.propertyType === "ApartmentUnitFlat") {
      return {
        images: [
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/unit/Web/bath.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/unit/Web/bed.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/unit/Web/Downloaded-premium50928505-Balcony.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/unit/Web/Downloaded-premium50928619-Commonspace.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/unit/Web/kitchen.jpg",
        ],
        video:
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/unit/Video/ROCKY+POINT+VIDEO.mp4",
      };
    }

    if (property.propertyType === "Duplex" || property.propertyType === "Villa" || property.propertyType === "Townhouse") {
      return {
        images: [
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/attached-dual-occupancy/Web/Settings+-921.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/attached-dual-occupancy/Web/dinning.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/attached-dual-occupancy/Web/outline.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/attached-dual-occupancy/Web/yard.jpg",
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/attached-dual-occupancy/Web/Settings+-945.jpg",
        ],
        video:
          "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/media/attached-dual-occupancy/Video/9+Clancy+Video.mp4",
      };
    }

    // Default fallback
    return { images: [], video: null };
  };

  const media = getMediaForProperty();

  return (
    <div>
      <div className="w-full h-[90vh] relative py-16">
        <video
          autoPlay
          muted
          loop
          preload="metadata"
          playsInline
          webkit-playsinline="true"
          className="w-full h-full object-cover"
        >
          <source
            src="https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/videos/postlist-video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div
        className="max-w-xl mx-auto flex flex-col items-center justify-center px-8 py-16"
        id="PreList"
      >
        <div className="w-full space-y-32 flex flex-col items-center text-center">
          <p style={{ fontSize: "32px" }} className="px-8">
            I am grateful to start this process with you
          </p>
          <div className="space-y-8">
            <img
              src={booking?.agent?.image}
              alt="Agent"
              className="max-h-[600px]"
            ></img>
            <p>
              {booking?.agent?.firstName} {booking?.agent?.lastName}
            </p>
          </div>

          <p className="px-8" style={{ fontSize: "32px" }}>
            “It’s not just a sale it means more. The maximum outcome is life
            changing”
          </p>
          <p className="text-xl px-8">
            Steps We'll discuss to maximise the outcome
          </p>
        </div>

        {/* Steps */}
        <div className="flex flex-col items-center py-32">
          {/* Maximum Price */}
          <div>
            <p className="text-center uppercase  font-semibold">
              Maximum Price
            </p>
            <div className="py-32 px-4">
              <p className="text-center text-lg">
                There is a difference between a sale and the maximum outcome,
                the experience will be anchored forever.
              </p>
            </div>
          </div>

          {/* Process */}
          <div>
            <p className="text-center uppercase  font-semibold">Process</p>
            <div className="py-32 px-4">
              <p className="text-center text-lg">
                Identifying the right process is the difference between a good
                outcome and the maximum outcome. This is something we will
                assess carefully.
              </p>
            </div>
          </div>

          {/* Marketing */}
          <div>
            <p className="text-center uppercase  font-semibold">
              Recent maximum outcomes
            </p>
            <div className="py-32 px-4">
              <div className="w-full space-y-8">
                {media.images.map((image, index) => (
                  <div key={index} className="mb-12">
                    <img
                      src={image}
                      className="w-full h-auto"
                      alt={`Media ${index}`}
                    />
                  </div>
                ))}
              </div>

              {media.video && (
                <div className="w-full h-[90vh] relative py-16">
                  <video
                    autoPlay
                    muted
                    loop
                    preload="metadata"
                    playsInline
                    webkit-playsinline="true"
                    className="w-full h-full object-cover"
                  >
                    <source src={media.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </div>
          </div>

          {/* Calendar */}
          <div>
            <p className="text-center uppercase  font-semibold">Calendar</p>
            <div className="py-32 px-4">
              <p className="text-center text-lg">
                After our meeting we will prepare a timeline of events.
              </p>
            </div>
          </div>

          {/* Get Started */}
          <div>
            <p className="text-center uppercase font-semibold">Get Started</p>
          </div>
        </div>

        <p className="text-center leading-6">
          "Home is a theater of dreams. What we mean by that is it's an anchor
          moment in someone's life when they move from one home to another, no
          matter the circumstances. This takes belief. It doesn't matter where
          they are; it could be somewhere in the desert, in a tent, or a $200
          million home. Whether it's a rental or an investment, a first-time
          home buyer, or a builder pouring their heart and soul into a project,
          at every level of life, it's a vision, a feeling of achievement that
          takes courage. It takes vision. The maximum outcome becomes
          life-changing. We are grateful to be a part of that."
        </p>
      </div>
      <footer className="bg-black text-white mt-16 py-24 text-center flex items-center justify-center">
        <img src={ausrealtyLogo} alt="AUSREALTY" />
      </footer>
    </div>
  );
};

export default PreList;
