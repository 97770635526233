import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { showToast } from "./ui/toast";

const GooglePlacesAutocomplete = ({
  onSelectAddress,
  isNSWCheck = true,
  address,
  setAddress,
}) => {
  const [autocomplete, setAutocomplete] = useState(null);

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const formattedAddress = place.formatted_address || "";

      // Check if the address is in NSW
      if (isNSWCheck) {
        const isInNSW = place.address_components.some(
          (component) =>
            component.short_name === "NSW" || component.short_name === "nsw"
        );

        if (isInNSW) {
          setAddress(formattedAddress);
          if (onSelectAddress) {
            onSelectAddress(formattedAddress);
          }
        } else {
          setAddress(""); // Clear the address field
          showToast("error", "Only NSW properties are allowed.");
          if (onSelectAddress) {
            onSelectAddress(""); // Clear the selected address in the parent component
          }
        }
      } else {
        setAddress(formattedAddress);
        if (onSelectAddress) {
          onSelectAddress(formattedAddress);
        }
      }
    }
  };

  const handleInputChange = (e) => {
    setAddress(e.target.value);
    // Do not call onSelectAddress here
  };

  return (
    <Autocomplete
      onLoad={handleLoad}
      onPlaceChanged={handlePlaceChanged}
      options={{
        componentRestrictions: { country: ["au"] },
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["geocode"],
      }}
      className="w-full"
    >
      <div className="relative text-xs">
        <input
          type="text"
          value={address}
          onChange={handleInputChange}
          placeholder="Enter an address..."
          className="w-full relative z-10 flex-grow p-2 border border-mediumgray rounded outline-none focus:outline-none resize-none overflow-y-hidden min-h-[38px]"
        />
      </div>
    </Autocomplete>
  );
};

export default GooglePlacesAutocomplete;
