import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../utils/axios";
import Slider from "react-slick";
import PageLoader from "../components/ui/PageLoader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/style.css";

const PropertyDetail = () => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axios.get(`/admin/property/${id}`);
        setProperty(response.data.data); // Adjust this if your data structure is different
      } catch (error) {
        console.error("Error fetching property details:", error);
      }
    };

    fetchProperty();
  }, [id]);

  const [saleProperties, setSaleProperties] = useState([]);
  const [soldProperties, setSoldProperties] = useState([]);

  useEffect(() => {
    const fetchRecommendProperties = async () => {
      try {
        const response = await axios.get(`/admin/property/recommend/${id}`);
        const sortedSaleProperties = response.data.data.recommendedSales.sort(
          (a, b) => b.score - a.score
        );
        const sortedSoldProperties = response.data.data.recommendedSold.sort(
          (a, b) => b.score - a.score
        );
        setSaleProperties(sortedSaleProperties);
        setSoldProperties(sortedSoldProperties);
      } catch (error) {
        console.error("Error fetching recommended properties:", error);
      }
    };

    fetchRecommendProperties();
  }, [id]);

  const formatCurrency = (value) => {
    if (value === undefined || value === null) return "N/A";

    return "$" + new Intl.NumberFormat().format(value);
  };

  if (!property) {
    return <PageLoader />;
  }

  // Slider settings for Slick
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    adaptiveHeight: true,
    arrows: true,
    // centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Filter out unnecessary fields
  const filteredProperty = Object.entries(property)
    .filter(
      ([key, value]) =>
        ![
          "isDeleted",
          "deletedAt",
          "createdAt",
          "updatedAt",
          "__v",
          "_id",
          "media",
        ].includes(key)
    )
    .sort(([, a], [, b]) => {
      const isANull = a === null || a === undefined || a === "N/A";
      const isBNull = b === null || b === undefined || b === "N/A";
      if (isANull && !isBNull) return 1;
      if (!isANull && isBNull) return -1;
      return 0;
    });

  return (
    <div className="container">
      <div>
        <h2>Recommended Sold Properties</h2>
        <Slider {...settings} className="mx-4">
          {soldProperties.map(({ property, score, keyMatches }) => (
            <div
              key={property._id}
              className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-neutral-50 hover:bg-neutral-100 py-4 px-6"
            >
              <img
                className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] "
                src={property.media[0]?.url || "/placeholder-image.jpg"}
                alt="property"
              />
              <div className="text-center mt-4">
                <p className="font-semibold mb-3">
                  {formatCurrency(property.price)} ({score}%)
                </p>
                <p className="mb-3 text-sm">{property.address}</p>
                <div className="flex items-center justify-center gap-4">
                  <div className="text-sm">
                    <i className="fa fa-bed me-2"></i>
                    {property.bedrooms || "N/A"}
                  </div>
                  <div className="text-sm">
                    <i className="fa fa-bath me-2"></i>
                    {property.bathrooms || "N/A"}
                  </div>
                  <div className="text-sm">
                    <i className="fa fa-car me-2"></i>
                    {property.carspaces || "N/A"}
                  </div>
                  <div className="text-sm">{property.propertyType}</div>
                </div>
                <div className="text-start">
                  <h4 className="text-sm">Key Matches</h4>
                  <div className="italic text-xs">
    {Object.entries(keyMatches).map(([key, value]) => (
      <p key={key}>
        {key}: {value}
      </p>
    ))}
  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <br></br>
      <div>
        <h2>Recommended Sale Properties</h2>
        <Slider {...settings} className="mx-4">
          {saleProperties.map(({ property, score, keyMatches }) => (
            <div
              key={property._id}
              className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-neutral-50 hover:bg-neutral-100 py-4 px-6"
            >
              <img
                className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] "
                src={property.media[0]?.url || "/placeholder-image.jpg"}
                alt="property"
              />
              <div className="text-center mt-4">
                <p className="font-semibold mb-3">
                  {formatCurrency(property.price)} ({score}%)
                </p>
                <p className="mb-3 text-sm">{property.address}</p>
                <div className="flex items-center justify-center gap-4">
                  <div className="text-sm">
                    <i className="fa fa-bed me-2"></i>
                    {property.bedrooms || "N/A"}
                  </div>
                  <div className="text-sm">
                    <i className="fa fa-bath me-2"></i>
                    {property.bathrooms || "N/A"}
                  </div>
                  <div className="text-sm">
                    <i className="fa fa-car me-2"></i>
                    {property.carspaces || "N/A"}
                  </div>
                  <div className="text-sm">{property.propertyType}</div>
                </div>
                <div className="text-start">
                  <h4 className="text-sm">Key Matches</h4>
                  <div className="italic text-xs">
    {Object.entries(keyMatches).map(([key, value]) => (
      <p key={key}>
        {key}: {value}
      </p>
    ))}
  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <br></br>
      {/* Property Details Table */}
      <h2 className="text-xl font-semibold mb-4">Property Details</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <tbody>
            {filteredProperty.map(([key, value], index) => (
              <tr
                key={key}
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                } border-b`}
              >
                <td className="px-6 py-4 font-medium  capitalize">
                  {key.replace(/([A-Z])/g, " $1")}
                </td>
                <td className="px-6 py-4  break-words">
                  {value === null || value === undefined ? (
                    "N/A"
                  ) : typeof value === "object" && value !== null ? (
                    <pre className="whitespace-pre-wrap break-words">
                      {JSON.stringify(value, null, 2)}
                    </pre>
                  ) : (
                    value.toString()
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br></br>
      {/* Slick Slider for Images */}
      <div className="mb-8">
        <Slider {...settings} className="mx-2">
          {property.media
            .filter((item) => item.category === "image")
            .map((image, index) => (
              <div key={index} className="p-2">
                <img
                  src={image.url}
                  alt={`Property image ${index + 1}`}
                  className="rounded-lg w-full h-auto max-w-[300px]"
                />
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default PropertyDetail;
