// App.js
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./utils/ProtectedRoute";
import CompleteProfile from "./pages/CompleteProfile";
import Navbar from "components/ui/Navbar";
import PropertyTable from "./pages/Property";
import PropertyDetail from "./pages/SingleProperty";
import Sandy from "./pages/Sandy";
import StartCampaign from "pages/StartCampaign";

import Chat from "pages/Chat";
import NotFound from "pages/NotFound";
import { ToastContainer } from "react-toastify";
import QuickSearch from "pages/QuickSearch";
import QuickSearchShare from "pages/share/QuickSearchShare";

import { useJsApiLoader } from "@react-google-maps/api";
import PreList from "pages/share/Prelist";
import PostListShare from "pages/share/PostlistShare";
import Profile from "pages/Profile";

const libraries = ["places"]; // Removed "maps" as it's invalid

const App = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY, // Ensure this is correctly set
    libraries, // Corrected libraries
    version: "weekly",
  });

  const location = useLocation();

  const hideNavbarRoutes = ["/login", "/share", "/prelist", "/postlist"];

  if (loadError) {
    return <div>Error loading Google Maps. Please try again later.</div>;
  }

  if (!isLoaded) {
    return <div></div>; // You can replace this with a loader/spinner component
  }

  return (
    <AuthProvider>
      {!hideNavbarRoutes.some((route) =>
        location.pathname.startsWith(route)
      ) && <Navbar />}
      <ToastContainer />
      <Routes>
        <Route path="*" element={<NotFound />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />

        <Route
          path="/complete-profile"
          element={
            <ProtectedRoute>
              <CompleteProfile />
            </ProtectedRoute>
          }
        />

<Route
          path="/profile"
          element={
            <ProtectedRoute>
         <Profile/>
            </ProtectedRoute>
          }
        />

        <Route
          path="/sandy"
          element={
            <ProtectedRoute>
              <Sandy />
            </ProtectedRoute>
          }
        />

        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <StartCampaign />
            </ProtectedRoute>
          }
        />

        <Route
          path="/quick-search"
          element={
            <ProtectedRoute>
              <QuickSearch />
            </ProtectedRoute>
          }
        />

        <Route
          path="/share/:id"
          element={
            <ProtectedRoute>
              <QuickSearchShare />
            </ProtectedRoute>
          }
        />

        <Route
          path="/chat/:address"
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />

        <Route path="/property" element={<PropertyTable />} />
        <Route path="/property/:id" element={<PropertyDetail />} />
        <Route path="/prelist/:prelistLink" element={<PreList />} />
        <Route path="/postlist/:postlistLink" element={<PostListShare />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
