import React from "react";

const DeleteField = ({ onDelete }) => {
  return (
    <span
      className="cursor-pointer text-black"
      onClick={onDelete}
    >
      <i className="fa-solid fa-trash"></i>
    </span>
  );
};

export default DeleteField;
