import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "utils/axios";
import PageLoader from "components/ui/PageLoader"; // Assuming this is your loader component
import Error from "components/ui/Error";
import PriceProcess from "components/chat/PriceProcess";
import PostList from "components/chat/Postlist";
import BookAppraisal from "components/chat/BookAppraisal";
import AuthoriseSchedule from "components/chat/AuthoriseSchedule";
import GoLive from "components/chat/GoLive";
import PrepareMarketing from "components/chat/PrepareMarketing";
import OnMarket from "components/chat/OnMarket";

const componentsMap = {
  "Price and Process": PriceProcess,
  "Post List": PostList,
  "Book Appraisal": BookAppraisal,
  "Authorise and Schedule": AuthoriseSchedule,
  "Prepare Marketing":PrepareMarketing,
  "Go Live":GoLive,
  "On Market":OnMarket
};

const Chat = () => {
  const { address } = useParams();
  const decodedAddress = decodeURIComponent(address);

  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [property, setProperty] = useState(null);
  const [selectedBox, setSelectedBox] = useState("");
  const [isBoxLoading, setIsBoxLoading] = useState(false);

  // Refs to each box for scrolling into view
  const boxRefs = useRef([]);
  const scrollContainerRef = useRef(null); // Container ref for scrolling

  const [boxes, setBoxes] = useState([
    {
      title: "Book Appraisal",
      text: "book now, instantly send valuable insights",
      status: "unlock",
    },
    {
      title: "Price and Process",
      text: "sets the price, plans the process, maximises the outcome",
      status: "unlock",
    },
    {
      title: "Post List",
      text: "instant proposals, high state impact",
      status: "unlock",
    },
    {
      title: "Authorise and Schedule",
      text: "schedule campaign, sign instantly",
      status: "unlock",
    },
    {
      title: "Prepare Marketing",
      text: "creates and approves all in one place",
      status: "unlock",
    },
    {
      title: "Go Live",
      text: "launch to market, adjust campaign anytime",
      status: "unlock",
    },
    {
      title: "On Market",
      text: "tracks, analyzes, and improves for maximum results",
      status: "unlock",
    },
  ]);

  const searchProperty = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/userProperty", {
        address: decodedAddress,
      });

      if (response.data.success) {
        setProperty(response.data.data);
        checkBoxStatus(response.data.data.boxStatus);
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkBoxStatus = async (boxStatus = null) => {
    try {
      if (!boxStatus) {
        const response = await axios.get(
          `/userProperty/${encodeURIComponent(decodedAddress)}`
        );
        if (response.data.success) {
          boxStatus = response.data.data.boxStatus;
        }
      }

      if (boxStatus) {
        const updatedBoxes = boxes.map((box) => {
          const status = boxStatus.find((b) => {
            switch (b.name) {
              case "bookAppraisal":
                return box.title === "Book Appraisal";
              case "priceProcess":
                return box.title === "Price and Process";
              case "postList":
                return box.title === "Post List";
              case "authoriseSchedule":
                return box.title === "Authorise and Schedule";
              case "prepareMarketing":
                return box.title === "Prepare Marketing";
              case "goLive":
                return box.title === "Go Live";
              case "onMarket":
                return box.title === "On Market";
              default:
                return false;
            }
          })?.status;

          return {
            ...box,
            status: status || box.status,
          };
        });

        setBoxes(updatedBoxes);

        const selectedBoxStatus = updatedBoxes.find(
          (box) => box.title === selectedBox
        )?.status;

        if (selectedBoxStatus === "lock") {
          navigate(`/chat/${encodeURIComponent(decodedAddress)}`);
        }
      }
    } catch (error) {
      console.error("Error fetching box status:", error);
    }
  };

  const handleBoxClick = (box, index) => {
    if (box.status !== "lock") {
      const tabName = box.title.toLowerCase().replace(/\s+/g, "-");
      navigate({ search: `?tab=${tabName}` });
      setSelectedBox(box.title);
      scrollToCenter(index); // Scroll to the clicked tab
    }
  };

  const scrollToCenter = (index) => {
    const selectedBoxElement = boxRefs.current[index];
    const scrollContainer = scrollContainerRef.current;

    if (selectedBoxElement && scrollContainer) {
      const containerWidth = scrollContainer.clientWidth; // Width of the scroll container
      const boxWidth = selectedBoxElement.clientWidth; // Width of the selected box
      const boxOffset = selectedBoxElement.offsetLeft; // Left position of the selected box

      // Calculate the scroll position to center the box
      const scrollPos = boxOffset - containerWidth / 2 + boxWidth / 2 - 20;

      // Smooth scrolling to the calculated position
      scrollContainer.scrollTo({ left: scrollPos, behavior: "smooth" });
    }
  };

  useEffect(() => {
    searchProperty();
    const initialTab = new URLSearchParams(location.search).get("tab");
    const selectedIndex = boxes.findIndex(
      (box) => box.title.toLowerCase().replace(/\s+/g, "-") === initialTab
    );
    if (initialTab && selectedIndex !== -1) {
      setSelectedBox(boxes[selectedIndex].title);
      scrollToCenter(selectedIndex); // Scroll to the selected tab from URL
    }
  }, [address, location.search]);

  useEffect(() => {
    // Scroll the selected box into center when component is fully rendered
    const selectedIndex = boxes.findIndex((box) => box.title === selectedBox);
    if (selectedIndex !== -1) {
      scrollToCenter(selectedIndex);
    }
  }, [boxes, selectedBox]);

  //   useEffect(() => {
  //     const newTab = new URLSearchParams(location.search).get('tab');
  //     if (newTab === 'comms-log') {
  //       setSelectedBox('Comms Log');
  //     } else {
  //       setSelectedBox(
  //         boxes.find((box) => box.title.toLowerCase().replace(/\s+/g, '-') === newTab)?.title || ''
  //       );
  //     }
  //     scrollToActiveTab();
  //   }, [location.search]);

  const CurrentComponent = componentsMap[selectedBox] || null;

  if (error) {
    return <Error />;
  }

  return (
    <div className="container">
      {!selectedBox && (
        <div className="py-8">
          <p className="text-center text-sm text-darkergray">
            {decodedAddress}
          </p>
        </div>
      )}

      {/* Render the tabs regardless of loading state */}
      <div
        className={`w-full fixed left-0 right-0 bg-white px-6 flex items-center justify-center ${
          selectedBox === "Post List" &&
          boxes.find((box) => box.title === "Post List")?.status !== "complete"
            ? "bottom-8 pt-2 pb-12"
            : "bottom-0 pb-12 pt-2"
        }`}
        style={{ zIndex: 1001 }}
      >
        <div
          ref={scrollContainerRef}
          className="overflow-x-auto whitespace-nowrap box-scrollbar scroll-smooth"
        >
          {boxes.map((box, index) => (
            <div
              key={index}
              ref={(el) => (boxRefs.current[index] = el)}
              className={`rounded-xl flex-shrink-0 inline-flex flex-col items-center mr-4 py-2.5 px-6
                ${
                  box.status === "lock"
                    ? "cursor-default bg-lightgray"
                    : "cursor-pointer "
                }
                ${
                  box.title === selectedBox && box.status !== "complete"
                    ? "bg-mediumgray"
                    : ""
                }
                ${
                  box.status === "complete"
                    ? "bg-black text-white hover:bg-black"
                    : " bg-lightgray hover:bg-mediumgray"
                }
                `}
              onClick={() => handleBoxClick(box, index)}
            >
              <div
                className={`relative  ${
                  isBoxLoading || box.title === selectedBox
                    ? "text-center min-w-[200px]"
                    : "text-start"
                }`}
              >
                {!isBoxLoading || box.title !== selectedBox ? (
                  <div className="text-xs m-0">
                    <div className="flex items-center">
                      {box.status === "lock" && (
                        <i className="fas fa-lock mr-2"></i>
                      )}
                      <h6>{box.title}</h6>
                    </div>
                    <span className=" text-darkergray">{box.text}</span>
                  </div>
                ) : (
                  <i className="fa-solid fa-spinner animate-spin"></i>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Conditional rendering for the content of the selected tab */}
      <div className="pb-28">
        {loading ? (
          <div
            className="fixed top-0 left-0 w-full h-full flex justify-center items-center"
            style={{
              zIndex: 1000,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <PageLoader />
          </div>
        ) : (
          CurrentComponent && (
            <CurrentComponent
              property={property}
              updateBoxLoading={setIsBoxLoading}
              updateBoxStatus={checkBoxStatus}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Chat;
