import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  const location = useLocation();

    // Allow access to /share URLs without login restriction
    if (location.pathname.startsWith("/share")) {
      return children;
    }

  if (loading) {
    return <p></p>
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!user.profileComplete && location.pathname !== "/complete-profile") {
    return <Navigate to="/complete-profile" />;
  }

  if (user.profileComplete && location.pathname === "/complete-profile") {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
