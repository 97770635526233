import React, { useEffect, useContext } from "react";
import { AuthContext } from "context/AuthContext";
import logo from "assets/images/logo.png";
import Button from "components/ui/Button";

const googleLogin = () => {
  window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
};

const Login = () => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      window.location.href = "/";
    }
  }, [user]);
  return (
    <div className="container min-h-screen flex flex-col items-center justify-center">
      <img
        src={logo}
        alt="Beleef Logo"
        className="max-w-48 mx-auto h-auto mb-16"
      ></img>

      <Button
        onClick={googleLogin}
        className="gray-button py-2.5 min-w-[250px]"
      >
        Continue with Google
      </Button>
    </div>
  );
};

export default Login;
