import Button from "components/ui/Button";
import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "utils/axios";
import SignatureCanvas from "react-signature-canvas";
import { showToast } from "components/ui/toast";
import { AuthContext } from "context/AuthContext";

const SignaturePad = ({ onSave, loading, clearCanvas }) => {
  const sigCanvas = useRef({});

  const clear = () => {
    sigCanvas.current.clear();
  };

  const save = () => {
    if (!sigCanvas.current.isEmpty()) {
      const trimmedCanvas = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      onSave(trimmedCanvas);
    }
  };

  // Clear canvas after successful upload
  useEffect(() => {
    if (clearCanvas) {
      sigCanvas.current.clear(); // Clear the signature pad
    }
  }, [clearCanvas]);

  return (
    <div className="text-start mb-8">
      <p>Please Sign Below</p>
      <div className="border border-mediumgray overflow-hidden">
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{
            className:
              "w-full max-w-[400px] h-[180px] signature-canvas bg-white",
          }}
        />
      </div>
      <div className="flex justify-between mt-4">
        <Button onClick={clear} className="gray-button">
          Clear
        </Button>
        <Button
          onClick={save}
          className="black-button"
          loading={loading}
          disabled={loading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const Profile = () => {
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("Sign");
  const [signature, setSignature] = useState(null);
  const [image, setImage] = useState(user.picture);
  const [loading, setLoading] = useState(false);
  const [clearCanvas, setClearCanvas] = useState(false); // State to clear the canvas
  const fileInputRef = useRef(null); // Reference to file input
  const [hasNewImage, setHasNewImage] = useState(false);

  useEffect(() => {
    const fetchSignatureUrl = async () => {
      try {
        const response = await axios.get("/profile/get-signature-url");
        if (response.data.success) {
          const imageUrl = response.data.data;
          setSignature(imageUrl);
        }
      } catch (error) {
        console.error("Error fetching signature URL:", error);
      }
    };

    fetchSignatureUrl();
  }, []);

  const handleSaveSignature = async (signature) => {
    try {
      setLoading(true);
      const { data: presignedData } = await axios.get(
        "/profile/generate-presigned-url"
      );

      const response = await fetch(signature);
      const blob = await response.blob();

      await fetch(presignedData.uploadURL, {
        method: "PUT",
        body: blob,
        headers: {
          "Content-Type": "image/png",
        },
      });

      const saveResponse = await axios.post("/profile/complete-profile", {
        s3Key: presignedData.key,
      });

      if (saveResponse.data.success) {
        setSignature(signature);
        showToast("success", "Signature updated successfully");
        setClearCanvas(true); // Clear the canvas after successful upload
      }
    } catch (error) {
      console.log(error.message);
      showToast("error", "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Update the image preview
        setHasNewImage(true); // Set the flag that a new image is uploaded
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveImage = async () => {
    try {
      setLoading(true);

      const saveResponse = await axios.post("/profile/complete-profile", {
        image,
      });

      if (saveResponse.data.success) {
        showToast("success", "Image updated successfully");
        setHasNewImage(false); // Reset the flag after saving the image
      }
    } catch (error) {
      console.log(error.message);
      showToast("error", "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const [initialFormData, setInitialFormData] = useState({
    mobile: user.mobile,
    company: user.company,
    title: user.title,
  });

  const [formData, setFormData] = useState({
    mobile: user.mobile,
    company: user.company,
    title: user.title,
  });

  const [isChanged, setIsChanged] = useState(false);

  const [mobileError, setMobileError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [titleError, setTitleError] = useState("");
  const mobileRegex =
    /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

  // Detect changes in formData
  useEffect(() => {
    const hasChanges = Object.keys(formData).some(
      (key) => formData[key] !== initialFormData[key]
    );
    setIsChanged(hasChanges);
  }, [formData, initialFormData]);

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));

    // Validation logic for each field
    switch (name) {
      case "mobile":
        setMobileError(
          !mobileRegex.test(value) ? "Mobile number is not valid" : ""
        );
        break;
      case "company":
        setCompanyError(!value ? "Company is required" : "");
        break;
      case "title":
        setTitleError(!value ? "Title is required" : "");
        break;
      default:
        break;
    }
  };

  const handleDetailsSave = async () => {
    // Create an object of changed fields
    const changedFields = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== initialFormData[key]) {
        changedFields[key] = formData[key];
      }
    });

    if (Object.keys(changedFields).length === 0) {
      showToast("info", "No changes to save");
      return;
    }

    try {
      setLoading(true);

      const saveResponse = await axios.post(
        "/profile/complete-profile",
        changedFields
      );

      if (saveResponse.data.success) {
        showToast("success", "Details updated successfully");
        // Update initialFormData to current formData
        setInitialFormData(formData);
        setIsChanged(false);
      }
    } catch (error) {
      console.log(error.message);
      showToast("error", "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const hasValidationErrors = Boolean(
    mobileError || companyError || titleError
  );

  return (
    <div className="max-w-lg mx-auto px-6 py-8 flex flex-col items-center">
      <h4>Edit Profile</h4>
      {/* Tabs container */}
      <div className="mt-4 w-full max-w-sm flex justify-center bg-lightgray p-1.5 rounded-md">
        {/* Sign Tab */}
        <div
          className={`flex-1 flex items-center justify-center p-2 rounded-md transition-all ease-in-out border-0 cursor-pointer ${
            activeTab === "Sign" ? "bg-white" : ""
          }`}
          onClick={() => setActiveTab("Sign")}
        >
          <i className="fa-solid fa-file-signature text-darkergray"></i>
          <span className="text-darkergray ml-2">Sign</span>
        </div>

        {/* Image Tab */}
        <div
          className={`flex-1 flex items-center justify-center p-2 rounded-md transition-all ease-in-out border-0 cursor-pointer ${
            activeTab === "Image" ? "bg-white" : ""
          }`}
          onClick={() => setActiveTab("Image")}
        >
          <i className="fa-solid fa-user text-darkergray"></i>
          <span className="text-darkergray ml-2">Image</span>
        </div>

        {/* Details Tab */}
        <div
          className={`flex-1 flex items-center justify-center p-2 rounded-md transition-all ease-in-out border-0 cursor-pointer ${
            activeTab === "Details" ? "bg-white" : ""
          }`}
          onClick={() => setActiveTab("Details")}
        >
          <i className="fa-solid fa-file text-darkergray"></i>
          <span className="text-darkergray ml-2">Details</span>
        </div>
      </div>

      {/* Content based on Active Tab */}
      <div className="mt-8 w-full text-center">
        {activeTab === "Sign" && (
          <>
            <SignaturePad
              onSave={handleSaveSignature}
              loading={loading}
              clearCanvas={clearCanvas}
            />
            {signature && (
              <div className="mt-8 text-start">
                <p>Your Signature</p>
                <div className="flex gap-4 items-end">
                  <img
                    src={signature}
                    alt="Your Signature"
                    className="w-auto p-2 h-16 object-fill border border-mediumgray"
                  />
                </div>
              </div>
            )}
          </>
        )}
        {activeTab === "Image" && (
          <div>
            <img
              src={image}
              alt="profile"
              className="w-full h-auto max-w-[200px] max-h-[200px] object-contain mx-auto"
            ></img>

            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              className="mt-4 float-left"
            />

            <Button
              className="black-button float-right mt-4"
              onClick={handleSaveImage}
              loading={loading}
              disabled={loading || !hasNewImage}
            >
              Save Image
            </Button>
          </div>
        )}
        {activeTab === "Details" && (
          <div className="grid gap-4 text-start">
            <div className="col-span-12 relative">
              <label className="form-label ">Mobile</label>
              <input
                type="text"
                name="mobile"
                className={`form-input border border-mediumgray ${
                  mobileError ? "border-red-500" : ""
                }`}
                value={formData.mobile}
                onChange={handleDetailsChange}
                placeholder="Enter mobile"
              />
              {mobileError && (
                <p className="text-red-500 text-sm">{mobileError}</p>
              )}
            </div>

            <div className="col-span-12 relative">
              <label className="form-label">Company</label>
              <select
                name="company"
                className={`form-select border ${
                  companyError ? "border-red-500" : "border-mediumgray"
                }`}
                value={formData.company}
                onChange={handleDetailsChange}
              >
                <option value="">Select Company</option>
                {[
                  "Ausrealty (Riverwood) Pty Ltd (Licensed user of Ausrealty)",
                  "KK Property Services Pty Ltd (Licensed user of Ausrealty)",
                  "I.M Group Pty Ltd (Licenced user of Ausrealty)",
                  "MRL Property Group Pty Ltd (Licenced user of Ausrealty)",
                  "Anodos Enterprises Pty Ltd (Licenced user of Ausrealty)",
                  "I Sayed Investments Pty Ltd (Licenced user of Ausrealty)",
                  "Suti Investments Pty Ltd (Licenced user of Ausrealty)",
                  "Hani Property Services Pty Ltd (Licenced user of Ausrealty)",
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {companyError && (
                <span className="form-error-message text-red-500 text-sm">
                  {companyError}
                </span>
              )}
            </div>

            <div className="col-span-12 relative">
              <label className="form-label">Title</label>
              <select
                name="title"
                className={`form-select border ${
                  titleError ? "border-red-500" : "border-mediumgray"
                }`}
                value={formData.title}
                onChange={handleDetailsChange}
              >
                <option value="">Select Title</option>
                {[
                  "Partner",
                  "Director",
                  "Associate Agent",
                  "Residential Sales Agent",
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {titleError && (
                <span className="form-error-message text-red-500 text-sm">
                  {titleError}
                </span>
              )}
            </div>

      
      <div className="col-span-12 relative mt-2">
      <Button
              className="black-button float-right"
              onClick={handleDetailsSave}
              loading={loading}
              disabled={loading || !isChanged || hasValidationErrors}
            >
              Save
            </Button>
      </div>

          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
