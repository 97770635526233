import React, { useState, useEffect, useRef } from "react";
import axios from "utils/axios";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const formatCurrency = (value) => {
  if (value === undefined || value === null) return "N/A";
  return "$" + new Intl.NumberFormat().format(value);
};

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  adaptiveHeight: true,
  arrows: true,
  // slidesToShow: 2, // Show only 2 slides if they are large in width
  // slidesToScroll: 1,
  variableWidth: true, // Allows variable width for slides
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

const RecommendedSalesProcess = ({
  address,
  recommendedSaleProcess,
  highEndProperties,
  lowEndProperties,
  developmentPotential,
  logicalPrice,
  propertyType,
  soldProperties,
  areaDynamics,
  duplexProperties,
}) => {
  const [processName, setProcessName] = useState(
    recommendedSaleProcess
  );
  const [openSection, setOpenSection] = useState(null);
  const [phaseContent, setPhaseContent] = useState([]);
  const [propertiesHighEnd, setPropertiesHighEnd] = useState(highEndProperties); // Moved to useState
  const [propertiesLowEnd, setPropertiesLowEnd] = useState(lowEndProperties); // Moved to useState

  const sectionRefs = useRef([]); // Create a ref array for sections

  const toggleSection = (index) => {
    // Collapse the previous section and open the clicked one
    setOpenSection((prevSection) => (prevSection === index ? null : index));

    // Ensure the content is scrolled into view when the section opens
    setTimeout(() => {
      const element = sectionRefs.current[index];
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        const offset = 100; // Custom offset from the top (e.g., 100px)

        // Scroll to the element with the offset
        window.scrollTo({
          top: elementPosition - offset,
          behavior: "smooth",
        });
      }
    }, 10); // Small delay to allow for rendering before scrolling
  };

  const [minPriceStr, maxPriceStr] = logicalPrice
    .replace(/[^\d.-]/g, "")
    .split("-");

  const minPrice = parseFloat(minPriceStr) * 1e6;
  const maxPrice = parseFloat(maxPriceStr) * 1e6;

  useEffect(() => {
    if (highEndProperties && lowEndProperties) {
      return;
    }

    console.log("Updating filtered properties...");

    const [minPriceStr, maxPriceStr] = logicalPrice
      .replace(/[^\d.-]/g, "")
      .split("-");
    const minPrice = parseFloat(minPriceStr) * 1e6;
    const maxPrice = parseFloat(maxPriceStr) * 1e6;

    // Get first 3 properties in the low-end range sorted by price (ascending)
    const lowEnd = soldProperties
      .filter(
        (propertyObj) =>
          propertyObj.property.price <= minPrice &&
          propertyObj.property.price !== null
      )
      .sort((a, b) => a.property.price - b.property.price) // Sort by price (ascending)
      .slice(0, 3); // Limit to first 3

    // Get first 3 properties in the high-end range sorted by price (descending)
    const highEnd = soldProperties
      .filter((propertyObj) => propertyObj.property.price >= maxPrice)
      .sort((a, b) => b.property.price - a.property.price) // Sort by price (descending)
      .slice(0, 3); // Limit to first 3

    setPropertiesLowEnd(lowEnd);
    setPropertiesHighEnd(highEnd);

    const updateRecommendedSaleProcess = async () => {
      try {
        await axios.put("/userProperty", {
          address,
          recommendedSaleProcess: processName,
          highEndProperties: propertiesHighEnd, // Make sure to use the updated state
        });
      } catch (error) {
        console.log(error.message);
      }
    };
    updateRecommendedSaleProcess();
  }, []);

  const propertiesInRange = soldProperties.filter(
    (propertyObj) =>
      propertyObj.property.price >= minPrice &&
      propertyObj.property.price <= maxPrice
  );

  const generatePhaseContent = (processName) => {
    switch (processName) {
      case "Auction - Development Site":
        return [
          // Phase 1: PRICE POINT
          <div key="phase-1" className="space-y-4">
            <p>{logicalPrice}</p>
            <p>Auction date displayed online with a backend price guide.</p>
            <p>
              Reasoning: Given the property is a development site, the profile
              of the purchasers best suit the auction method.
            </p>
            <p>Backend response:</p>

            <p>
              Price Guide: {logicalPrice}. We will have a more accurate
              indication on price once we receive buyer feedback.{" "}
            </p>

            <p>Auction on site: Date and Time.</p>
            <p>
              We have attached some further information in the following link{" "}
              <a href="https://l.ausrealty.com.au/t/5179d8">
                https://l.ausrealty.com.au/t/5179d8
              </a>
              which includes the following:
            </p>

            <ul className="list-none m-0">
              <li>Contract of Sale </li>
              <li>Privacy Act </li>
              <li>Photos </li>
              <li>Floor Plan </li>
              <li>Information about the area </li>
              <li>Details about the property</li>
            </ul>
          </div>,
          // Phase 2: ENGAGEMENT
          <div key="phase-2" className="space-y-4">
            <ul className="list-none m-0">
              <li>
                - Track enquiries, showings, and interest within the first two
                weeks.
              </li>
              <li>
                - Determine if over 20% of potential buyers show a strong
                interest.
              </li>
              <li>
                - Adjust marketing strategies as necessary to enhance
                engagement.
              </li>
            </ul>
          </div>,
          // Phase 3: PRICE SET
          <div key="phase-3" className="space-y-4">
            <Slider {...settings} className="w-full mr-4">
              {duplexProperties.map((property) => (
                <div
                  key={property._id}
                  className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                >
                  <img
                    className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                    src={property?.media[0]?.url || "/placeholder-image.jpg"}
                    alt="property"
                  />
                  <div className="text-center mt-4 space-y-3">
                    <p className="font-semibold">
                      {formatCurrency(property.price)}
                    </p>
                    <p className="text-sm">{property.address}</p>
                    <div className="flex items-center justify-center gap-4">
                      <div className="text-sm">
                        <i className="fa fa-bed me-2"></i>
                        {property.bedrooms || "N/A"}
                      </div>
                      <div className="text-sm">
                        <i className="fa fa-bath me-2"></i>
                        {property.bathrooms || "N/A"}
                      </div>
                      <div className="text-sm">
                        <i className="fa fa-car me-2"></i>
                        {property.carspaces || "N/A"}
                      </div>
                      <div className="text-sm">{property.propertyType}</div>
                      <div className="text-sm">
                        AI: {property?.aiPropertyType}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <ul className="list-none m-0">
              <li>- Test the top end of the price range.</li>
              <li>
                - If high engagement is observed, consider raising the guide
                price to test market response.
              </li>
              <li>
                - Continue monitoring engagement and be prepared to adjust the
                strategy if necessary.
              </li>
              <li>
                - Adjust the price guide based on the engagement data. If
                engagement is high, raise the guide slightly to test the market
                interest and comply with the property act.
              </li>
            </ul>
            <p>Dialog comes here</p>
          </div>,
          // Phase 4: OFFERS CLOSING DATE
          <div key="phase-4" className="space-y-4">
            <p>
              Determine if the date will remain the same, be moved forward, or
              moved back.
            </p>
          </div>,
          // Phase 5: FINALISE
          <div key="phase-5" className="space-y-4">
            <p>
              Ensure all interested parties are aware of the final date and
              encourage competitive offers. Set the auction date for 4 weeks
              from now to allow sufficient time for buyer engagement and
              marketing efforts.
            </p>
          </div>,
        ];
      case "Private Treaty Offers Closing":
        return [
          // Phase 1: PRICE POINT
          <div key="phase-1" className="space-y-4">
            <p>{logicalPrice}</p>
            <p>
              Offers invited online with a backend closing date of 3 weeks.
              Allowing more flexibility with no online changes and protecting
              the credibility of the property.
            </p>
            <p>
              Reasoning: Given there are 3 or more sold matches in the area
              which fall within the logical price range.
            </p>
            <p>Backend response:</p>

            <p>
              Price Guide: {logicalPrice}. We will have a more accurate
              indication on price once we receive buyer feedback.{" "}
            </p>

            <p>
              Offers closing: Date and Time. FAQs:{" "}
              <a href="https://l.ausrealty.com.au/t/5179d8">
                https://l.ausrealty.com.au/t/5179d8
              </a>
              We have attached some further information in the following link
              which includes the following:
            </p>

            <ul className="list-none m-0">
              <li>Contract of Sale </li>
              <li>Privacy Act </li>
              <li>Photos </li>
              <li>Floor Plan </li>
              <li>Information about the area </li>
              <li>Details about the property</li>
            </ul>
          </div>,
          // Phase 2: ENGAGEMENT
          <div key="phase-2" className="space-y-4">
            <ul className="list-none m-0">
              <li>- Track enquiries, showings, and the interest</li>
              <li>
                - Determine if over 20% of potential buyers show a strong
                interest.
              </li>
              <li>
                - Adjust marketing strategies as necessary to enhance
                engagement.
              </li>
            </ul>
          </div>,
          // Phase 3: PRICE SET
          <div key="phase-3" className="space-y-4">
            <div className="w-full flex items-center justify-end">
              <span>Edit</span>
              <i
                className="ml-2 text-sm fa-solid fa-pencil cursor-pointer"
                onClick={() => setEditHighMatch(true)}
              ></i>
            </div>
            <div className="w-full flex items-center justify-end">
              <span>Edit</span>
              <i
                className="ml-2 text-sm fa-solid fa-pencil cursor-pointer"
                onClick={() => setEditHighMatch(true)}
              ></i>
            </div>
            {propertiesHighEnd.length > 0 && (
              <Slider {...settings} className="w-full mr-4">
                {propertiesHighEnd.map(({ property, score, keyMatches }) => (
                  <div
                    key={property._id}
                    className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-col bg-lightgray hover:bg-mediumgray py-4 px-6"
                  >
                    <img
                      className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                      src={property?.media[0]?.url || "/placeholder-image.jpg"}
                      alt="property"
                    />
                    <div className="text-center mt-4 space-y-3">
                      <p className="font-semibold">
                        {formatCurrency(property.price)} ({score}%)
                      </p>
                      <p className="text-sm">{property.address}</p>
                      <div className="flex items-center justify-center gap-4">
                        <div className="text-sm">
                          <i className="fa fa-bed me-2"></i>
                          {property.bedrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-bath me-2"></i>
                          {property.bathrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-car me-2"></i>
                          {property.carspaces || "N/A"}
                        </div>
                        <div className="text-sm">{property.propertyType}</div>
                        <div className="text-sm">
                          AI: {property?.aiPropertyType}
                        </div>
                      </div>
                      <div className="text-xs text-start space-y-1">
                        <p className="font-semibold">Key Matches</p>
                        <p className="italic">{keyMatches.join(", ")}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
            <ul className="list-none m-0">
              <li>- Test the top end of the price range.</li>
              <li>
                - If high engagement is observed, consider raising the guide
                price to test market response.
              </li>
              <li>
                - Continue monitoring engagement and be prepared to adjust the
                strategy if necessary.
              </li>
              <li>
                - Adjust the price guide based on the engagement data. If
                engagement is high, raise the guide slightly to test the market
                interest and comply with the property act.
              </li>
            </ul>
            <p>Dialogue to display here</p>
          </div>,
          // Phase 4: OFFERS CLOSING DATE
          <div key="phase-4" className="space-y-4">
            <p>
              Determine if the date will remain the same, be moved forward,
              moved back or displayed live online.
            </p>
          </div>,
          // Phase 5: FINALISE
          <div key="phase-5" className="space-y-4">
            <p>
              Ensure all interested parties are aware of the final date and
              encourage competitive offers. The gap between buyer 1 and 2 may be
              $1 to $500,000. Each purchaser pays their maximum.
            </p>
          </div>,
        ];
      case "Private Treaty Offers Closing: Live Guide With Live Closing Date":
        return [
          // Phase 1: PRICE POINT
          <div key="phase-1" className="space-y-4">
            <p>{logicalPrice}</p>
            <p>
              Use a live price guide online with a live closing date set for 3
              weeks. Offers Closing (date): Price Guide{" "}
              <span>{logicalPrice}</span>
            </p>
            <p>
              Reasoning: Given there are greater than{" "}
              {propertyType === "ApartmentUnitFlat"
                ? "90 sales per year in the area (for units)"
                : "170 sales per year in the area (for houses)"}
              . There are 3 or more sold matches in the area which fall within
              the logical price range.
            </p>
            <p>Backend response:</p>

            <p>
              Price Guide: {logicalPrice}. We will have a more accurate
              indication on price once we receive buyer feedback.{" "}
            </p>

            <p>
              Offers closing: Date and Time. FAQs:{" "}
              <a href="https://l.ausrealty.com.au/t/5179d8">
                https://l.ausrealty.com.au/t/5179d8
              </a>
              We have attached some further information in the following link
              which includes the following:
            </p>

            <ul className="list-none m-0">
              <li>Contract of Sale </li>
              <li>Privacy Act </li>
              <li>Photos </li>
              <li>Floor Plan </li>
              <li>Information about the area </li>
              <li>Details about the property</li>
            </ul>
          </div>,
          // Phase 2: ENGAGEMENT
          <div key="phase-2" className="space-y-4">
            <ul className="list-none m-0">
              <li>- Track enquiries, showings, and the interest</li>
              <li>
                - Determine if over 20% of potential buyers show a strong
                interest.
              </li>
              <li>
                - Adjust marketing strategies as necessary to enhance
                engagement.
              </li>
            </ul>
          </div>,
          // Phase 3: PRICE SET
          <div key="phase-3" className="space-y-4">
            <div className="w-full flex items-center justify-end">
              <span>Edit</span>
              <i
                className="ml-2 text-sm fa-solid fa-pencil cursor-pointer"
                onClick={() => setEditHighMatch(true)}
              ></i>
            </div>
            <div className="w-full flex items-center justify-end">
              <span>Edit</span>
              <i
                className="ml-2 text-sm fa-solid fa-pencil cursor-pointer"
                onClick={() => setEditHighMatch(true)}
              ></i>
            </div>
            {propertiesHighEnd.length > 0 && (
              <Slider {...settings} className="w-full mr-4">
                {propertiesHighEnd.map(({ property, score, keyMatches }) => (
                  <div
                    key={property._id}
                    className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-col bg-lightgray hover:bg-mediumgray py-4 px-6"
                  >
                    <img
                      className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                      src={property?.media[0]?.url || "/placeholder-image.jpg"}
                      alt="property"
                    />
                    <div className="text-center mt-4 space-y-3">
                      <p className="font-semibold">
                        {formatCurrency(property.price)} ({score}%)
                      </p>
                      <p className="text-sm">{property.address}</p>
                      <div className="flex items-center justify-center gap-4">
                        <div className="text-sm">
                          <i className="fa fa-bed me-2"></i>
                          {property.bedrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-bath me-2"></i>
                          {property.bathrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-car me-2"></i>
                          {property.carspaces || "N/A"}
                        </div>
                        <div className="text-sm">{property.propertyType}</div>
                        <div className="text-sm">
                          AI: {property?.aiPropertyType}
                        </div>
                      </div>
                      <div className="text-xs text-start space-y-1">
                        <p className="font-semibold">Key Matches</p>
                        <p className="italic">{keyMatches.join(", ")}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
            <ul className="list-none m-0">
              <li>- Test the top end of the price range.</li>
              <li>
                - If high engagement is observed, consider raising the guide
                price to test market response.
              </li>
              <li>
                - Continue monitoring engagement and be prepared to adjust the
                strategy if necessary.
              </li>
              <li>
                - Adjust the price guide based on the engagement data. If
                engagement is high, raise the guide slightly to test the market
                interest and comply with the property act.
              </li>
            </ul>
            <p>Dialogue to display here</p>
          </div>,
          // Phase 4: OFFERS CLOSING DATE
          <div key="phase-4" className="space-y-4">
            <p>
              Determine if the date will remain the same, be moved forward or
              moved back. If there is a change to the date, we recommend
              “Contact Agent” online.
            </p>
          </div>,
          // Phase 5: FINALISE
          <div key="phase-5" className="space-y-4">
            <p>
              Ensure all interested parties are aware of the final date and
              encourage competitive offers. The gap between buyer 1 and 2 may be
              $1 to $500,000. Each purchaser pays their maximum.
            </p>
          </div>,
        ];
      case "Private Treaty Using Sales: No Guide Only Sales & Backend Closing Date":
        return [
          // Phase 1: PRICE POINT
          <div key="phase-1" className="space-y-4">
            <p>
              Offers invited online with a backend closing date of 3 weeks.
              Allowing more flexibility with no online changes and protecting
              the credibility of the property.
            </p>
            <p>
              Reasoning: Given there are under 3 sold matches in the area which
              fall within the logical price range.
            </p>
            <p>Backend response:</p>

            <p>Recent Sales</p>

            <div className="w-full flex items-center justify-end">
              <span>Edit</span>
              <i
                className="ml-2 text-sm fa-solid fa-pencil cursor-pointer"
                onClick={() => setEditHighMatch(true)}
              ></i>
            </div>

            {propertiesLowEnd.length > 0 && (
              <Slider {...settings} className="w-full mr-4">
                {propertiesLowEnd.map(({ property, score, keyMatches }) => (
                  <div
                    key={property._id}
                    className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-col bg-lightgray hover:bg-mediumgray py-4 px-6"
                  >
                    <img
                      className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                      src={property?.media[0]?.url || "/placeholder-image.jpg"}
                      alt="property"
                    />
                    <div className="text-center mt-4 space-y-3">
                      <p className="font-semibold">
                        {formatCurrency(property.price)} ({score}%)
                      </p>
                      <p className="text-sm">{property.address}</p>
                      <div className="flex items-center justify-center gap-4">
                        <div className="text-sm">
                          <i className="fa fa-bed me-2"></i>
                          {property.bedrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-bath me-2"></i>
                          {property.bathrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-car me-2"></i>
                          {property.carspaces || "N/A"}
                        </div>
                        <div className="text-sm">{property.propertyType}</div>
                        <div className="text-sm">
                          AI: {property?.aiPropertyType}
                        </div>
                      </div>
                      <div className="text-xs text-start space-y-1">
                        <p className="font-semibold">Key Matches</p>
                        <p className="italic">{keyMatches.join(", ")}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}

            <p>
              Price Guide: {logicalPrice}. We will have a more accurate
              indication on price once we receive buyer feedback.{" "}
            </p>

            <p>
              Offers closing: Date and Time. FAQs:{" "}
              <a href="https://l.ausrealty.com.au/t/5179d8">
                https://l.ausrealty.com.au/t/5179d8
              </a>
              We have attached some further information in the following link
              which includes the following:
            </p>

            <ul className="list-none m-0">
              <li>Contract of Sale </li>
              <li>Privacy Act </li>
              <li>Photos </li>
              <li>Floor Plan </li>
              <li>Information about the area </li>
              <li>Details about the property</li>
            </ul>
          </div>,
          // Phase 2: ENGAGEMENT
          <div key="phase-2" className="space-y-4">
            <ul className="list-none m-0">
              <li>
                - Track enquiries, showings, and interest within the first two
                weeks.
              </li>
              <li>
                - Determine if over 20% of potential buyers show a strong
                interest.
              </li>
              <li>
                - Adjust marketing strategies as necessary to enhance
                engagement.
              </li>
            </ul>
          </div>,
          // Phase 3: PRICE SET
          <div key="phase-3" className="space-y-4">
            <div className="w-full flex items-center justify-end">
              <span>Edit</span>
              <i
                className="ml-2 text-sm fa-solid fa-pencil cursor-pointer"
                onClick={() => setEditHighMatch(true)}
              ></i>
            </div>
            {propertiesHighEnd.length > 0 && (
              <Slider {...settings} className="w-full mr-4">
                {propertiesHighEnd.map(({ property, score, keyMatches }) => (
                  <div
                    key={property._id}
                    className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-col bg-lightgray hover:bg-mediumgray py-4 px-6"
                  >
                    <img
                      className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                      src={property?.media[0]?.url || "/placeholder-image.jpg"}
                      alt="property"
                    />
                    <div className="text-center mt-4 space-y-3">
                      <p className="font-semibold">
                        {formatCurrency(property.price)} ({score}%)
                      </p>
                      <p className="text-sm">{property.address}</p>
                      <div className="flex items-center justify-center gap-4">
                        <div className="text-sm">
                          <i className="fa fa-bed me-2"></i>
                          {property.bedrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-bath me-2"></i>
                          {property.bathrooms || "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-car me-2"></i>
                          {property.carspaces || "N/A"}
                        </div>
                        <div className="text-sm">{property.propertyType}</div>
                        <div className="text-sm">
                          AI: {property?.aiPropertyType}
                        </div>
                      </div>
                      <div className="text-xs text-start space-y-1">
                        <p className="font-semibold">Key Matches</p>
                        <p className="italic">{keyMatches.join(", ")}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}

            <ul className="list-none m-0">
              <li>- Test the top end of the price range.</li>
              <li>
                - If high engagement is observed, consider raising the guide
                price to test market response.
              </li>
              <li>
                - Continue monitoring engagement and adjust strategy if
                necessary.
              </li>
              <li>
                - Adjust the price guide based on engagement data to comply with
                the property act.
              </li>
            </ul>
            <p>Dialogue to display here</p>
          </div>,
          // Phase 4: OFFERS CLOSING DATE
          <div key="phase-4" className="space-y-4">
            <p>
              Determine if the date will remain the same, be moved forward,
              moved back or displayed live online.
            </p>
          </div>,
          // Phase 5: FINALISE
          <div key="phase-5" className="space-y-4">
            <p>
              Ensure all interested parties are aware of the final date and
              encourage competitive offers. The gap between buyer 1 and 2 may be
              $1 to $500,000. Each purchaser pays their maximum.
            </p>
          </div>,
        ];
      case "Auction":
        return [
          // Phase 1: PRICE POINT
          <div key="phase-1" className="space-y-4">
            <p>{logicalPrice}</p>
            <p>Auction date displayed online with a backend price guide.</p>
            <p>
              Reasoning: Given the property is a development site, the profile
              of the purchasers best suit the auction method.
            </p>
            <p>Backend response:</p>

            <p>Price Guide: {logicalPrice}.</p>
            <p>
              We will have a more accurate indication on price once we receive
              buyer feedback.
            </p>

            <p>Auction on site: Date and Time.</p>
            <p>
              We have attached some further information in the following link{" "}
              <a href="https://l.ausrealty.com.au/t/1511af ">
                https://l.ausrealty.com.au/t/1511af
              </a>
              which includes the following:
            </p>

            <ul className="list-none m-0">
              <li>Contract of Sale </li>
              <li>Privacy Act </li>
              <li>Photos </li>
              <li>Floor Plan </li>
              <li>Information about the area </li>
              <li>Details about the property</li>
            </ul>
          </div>,
          // Phase 2: ENGAGEMENT
          <div key="phase-2" className="space-y-4">
            <ul className="list-none m-0">
              <li>
                - Track enquiries, showings, and interest within the first two
                weeks.
              </li>
              <li>
                - Determine if over 20% of potential buyers show a strong
                interest.
              </li>
              <li>
                - Adjust marketing strategies as necessary to enhance
                engagement.
              </li>
            </ul>
          </div>,
          // Phase 3: PRICE SET
          <div key="phase-3" className="space-y-4">
            <ul className="list-none m-0">
              <li>- Test the top end of the price range.</li>
              <li>
                - If high engagement is observed, consider raising the guide
                price to test market response.
              </li>
              <li>
                - Continue monitoring engagement and be prepared to adjust the
                strategy if necessary.
              </li>
              <li>
                - Adjust the price guide based on the engagement data. If
                engagement is high, raise the guide slightly to test the market
                interest and comply with the property act.
              </li>
            </ul>
            <p>Dialog comes here</p>
          </div>,
          // Phase 4: OFFERS CLOSING DATE
          <div key="phase-4" className="space-y-4">
            <p>
              Determine if the date will remain the same, be moved forward, or
              moved back.
            </p>
          </div>,
          // Phase 5: FINALISE
          <div key="phase-5" className="space-y-4">
            <p>
              Ensure all interested parties are aware of the final date and
              encourage competitive offers. Set the auction date for 4 weeks
              from now to allow sufficient time for buyer engagement and
              marketing efforts.
            </p>
          </div>,
        ];
      default:
        return [];
    }
  };

  const checkProcess = (developmentPotential) => {
    if (developmentPotential !== null && developmentPotential) {
      setProcessName("Auction - Development Site");
      setPhaseContent(generatePhaseContent("Auction - Development Site")); // Update phase content dynamically
      return;
    }

    if (propertiesInRange.length >= 3) {
      if (
        (propertyType === "ApartmentUnitFlat" &&
          areaDynamics.unitStats?.annualSalesVolume >= 90) ||
        (propertyType !== "ApartmentUnitFlat" &&
          areaDynamics.houseStats?.annualSalesVolume >= 170)
      ) {
        setProcessName(
          "Private Treaty Offers Closing: Live Guide With Live Closing Date"
        );
        setPhaseContent(
          generatePhaseContent(
            "Private Treaty Offers Closing: Live Guide With Live Closing Date"
          )
        );
      } else {
        setProcessName("Private Treaty Offers Closing");
        setPhaseContent(generatePhaseContent("Private Treaty Offers Closing"));
      }
    } else {
      setProcessName(
        "Private Treaty Using Sales: No Guide Only Sales & Backend Closing Date"
      );
      setPhaseContent(
        generatePhaseContent(
          "Private Treaty Using Sales: No Guide Only Sales & Backend Closing Date"
        )
      );
    }
  };

  useEffect(() => {
    if (!processName) {
      checkProcess(developmentPotential);
    }
    if (processName) {
      setPhaseContent(generatePhaseContent(processName));
    }
  }, [
    logicalPrice,
    developmentPotential,
    soldProperties,
    propertyType,
    areaDynamics,
    propertiesHighEnd, // Added dependency
  ]);

  const handleProcessChange = (e) => {
    const selectedProcess = e.target.value;
    setProcessName(selectedProcess);
    setPhaseContent(generatePhaseContent(selectedProcess)); // Update phase content dynamically
    // Call the API to update only the process name
    axios
      .put("/userProperty", {
        address,
        recommendedSaleProcess: selectedProcess,
      })
      .catch((error) => {
        console.error("Error updating process name:", error);
      });
  };

  const [editHighMatch, setEditHighMatch] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (highEndProperties) {
      setPropertiesHighEnd(highEndProperties);
    }
    if (lowEndProperties) {
      setPropertiesLowEnd(lowEndProperties);
    }
  }, [highEndProperties, lowEndProperties]);

  const handleHighSave = async () => {
    try {
      setLoading(true);

      // Save to the backend
      const response = await axios.put("/userProperty", {
        address,
        recommendedSaleProcess: processName,
        highEndProperties: propertiesHighEnd, // Make sure to use the updated state
      });

      // If backend returns updated high-end properties, sync state with backend response
      if (response.data.data.highEndProperties) {
        setPropertiesHighEnd(response.data.data.highEndProperties);
      }
      console.log(propertiesHighEnd);

      setLoading(false);
      setEditHighMatch(false); // Close the modal on successful save
    } catch (error) {
      console.error("Error saving high-end properties:", error);
      setLoading(false);
    }
  };

  // Ensure that the new state is applied properly after clicking Save
  const handleHighCheckboxChange = (propertyId) => {
    setPropertiesHighEnd((prevChecked) => {
      const isAlreadyChecked = prevChecked.some(
        (highEndProperty) => highEndProperty.property._id === propertyId
      );

      if (isAlreadyChecked) {
        return prevChecked.filter(
          (highEndProperty) => highEndProperty.property._id !== propertyId
        );
      } else {
        const newProperty = soldProperties.find(
          (prop) => prop.property._id === propertyId
        );
        return newProperty ? [...prevChecked, newProperty] : prevChecked;
      }
    });
  };

  return (
    <div className="w-full space-y-8">
      <h4>Recommended Sales Process</h4>

      <select
        className="form-select border border-mediumgray p-2"
        value={processName}
        onChange={handleProcessChange}
      >
        <option value="Private Treaty Offers Closing">
          Private Treaty Offers Closing
        </option>
        <option value="Private Treaty Offers Closing: Live Guide With Live Closing Date">
          Private Treaty Offers Closing: Live Guide With Live Closing Date
        </option>
        <option value="Private Treaty Using Sales: No Guide Only Sales & Backend Closing Date">
          Private Treaty Using Sales: No Guide Only Sales & Backend Closing Date
        </option>
        <option value="Auction - Development Site">
          Auction - Development Site
        </option>
        <option value="Auction">Auction</option>
      </select>

      {Array.from({ length: 5 }, (_, i) => (
        <div
          key={i}
          className="mb-2"
          ref={(el) => (sectionRefs.current[i] = el)}
        >
          <button
            onClick={() => toggleSection(i)}
            className="w-full pb-4 pt-6 border-b border-mediumgray flex items-center justify-between"
          >
            <h4 className="font-light text-sm">{`STEP ${i + 1} ${
              [
                "PRICE POINT",
                "ENGAGEMENT",
                "PRICE ASSESSMENT",
                processName?.startsWith("Auction")
                  ? "AUCTION DATE"
                  : "OFFERS CLOSING DATE",
                "FINALISE",
              ][i]
            }`}</h4>
            <span className="float-right">
              {openSection === i ? (
                <i className="fas fa-chevron-down text-sm"></i>
              ) : (
                <i className="fas fa-chevron-right text-sm"></i>
              )}
            </span>
          </button>
          {openSection === i && (
            <div className="text-start py-4">{phaseContent[i]}</div>
          )}
        </div>
      ))}

      <Modal
        isOpen={editHighMatch}
        onClose={() => setEditHighMatch(false)}
        title="Edit"
        className="max-w-2xl"
      >
        {soldProperties && soldProperties.length > 0 && (
          <div className="w-full overflow-x-auto">
            <table className="w-full border-collapse text-center">
              <thead>
                <tr className="bg-lightgray">
                  <th className="py-2 px-3">Select</th>
                  <th className="py-2 px-3">Address</th>
                  <th className="py-2 px-3">Price</th>
                  <th className="py-2 px-3">Score Match</th>
                </tr>
              </thead>
              <tbody>
                {soldProperties.map(({ property, score }) => (
                  <tr key={property._id} className="border-b">
                    <td className="py-2 px-3">
                      <input
                        type="checkbox"
                        checked={propertiesHighEnd?.some(
                          (highEndProperty) =>
                            highEndProperty.property._id === property._id
                        )}
                        onChange={() => handleHighCheckboxChange(property._id)}
                      />
                    </td>
                    <td className="py-2 px-3">{property.address}</td>
                    <td className="py-2 px-3">{property.price}</td>
                    <td className="py-2 px-3">{score}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="flex justify-end mt-4">
          <Button
            onClick={handleHighSave}
            className="black-button"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default RecommendedSalesProcess;
