import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

// Register custom fonts
Font.register({
  family: "Times-Roman",
  src: "https://fonts.gstatic.com/s/timesnewroman/v18/cjZKeUmRSqm4dGWdSe_UX1RYOo1i1E3kkr5-K7-S.ttf",
});

// Define styles for the PDF with a more professional look
const styles = StyleSheet.create({
  page: {
    padding: 50,
    fontSize: 11,
    fontFamily: "Times-Roman",
    lineHeight: 1.5,
    color: "#000000",
  },
  logoContainer: {
    alignItems: "center",
    marginBottom: 20,
  },
  logo: {
    width: 200, // Adjust this value as needed
    height: "auto",
  },
  section: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: "bold",
  },
  listItem: {
    flexDirection: "row",
    marginBottom: 5,
  },
  bullet: {
    width: 10,
    fontSize: 10,
  },
  signatureSection: {
    marginTop: 20,
  },
  signature: {
    width: 80,
    height: 30,
    marginTop: 10,
    marginBottom: 10,
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 7, // Adjust this value to match the original image text size
    color: "#666666", // Adjust this color to match the original image text color
  },
});

// PDF Document component
const RentalAppraisalPDF = ({
  selectedAddress,
  price,
  user,
  logo,
  signature,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={logo} />
      </View>

      <View style={styles.section}>
        <Text>
          {new Date().toLocaleDateString("en-AU", {
            timeZone: "Australia/Sydney",
          })}
        </Text>
      </View>

      <View style={styles.section}>
        <Text>To Whom It May Concern,</Text>
      </View>

      <View style={styles.section}>
        <Text>
          PROPERTY: <Text style={styles.bold}>{selectedAddress}</Text>
        </Text>
      </View>

      <View style={styles.section}>
        <Text>
          In appraising the above-mentioned property, we have taken into account
          several items such as:
        </Text>
        <View style={{ marginLeft: 20, marginTop: 12 }}>
          <View style={styles.listItem}>
            <Text style={styles.bullet}>•</Text>
            <Text>Properties recently leased within the area</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.bullet}>•</Text>
            <Text>Properties we are currently marketing</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.bullet}>•</Text>
            <Text>Our knowledge of the local area</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.bullet}>•</Text>
            <Text>Current leasing enquiry</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text>
          A statistical leasing price for your property in the current market is{" "}
          <Text style={styles.bold}>{price}</Text> per week.
        </Text>
      </View>

      <View style={styles.section}>
        <Text>
          Although care has been taken in arriving at this figure, we note that
          this is an opinion only, of what would be a reasonable rent attainable
          and is not to be taken as a formal valuation of the rental. If a
          formal valuation of the rental is required, a registered Valuer should
          be retained.
        </Text>
      </View>

      <View style={styles.section}>
        <Text>
          Please feel free to contact our office if you have any further
          queries.
        </Text>
      </View>

      <View style={styles.signatureSection}>
        <Text>Yours faithfully,</Text>
        {signature && (
          <Image
            style={styles.signature}
            src={{
              uri: signature,
              method: "GET",
              // headers: { "Cache-Control": "no-cache" },
            }}
          />
        )}
        <Text>
          {user.name}
        </Text>
        <Text>{user.title}</Text>
      </View>

      <View style={styles.footer}>
        <Text>{user.company}</Text>
        <Text>{user.abn}</Text>
        {/* <Text>Licensed user of Ausrealty pending trademark</Text>
        <Text>166 Belmore Road, Riverwood NSW 2210</Text>
        <Text>9011 6888</Text>
        <Text>riverwood@ausrealty.com.au</Text>
        <Text>ausrealty.com.au</Text>
        <Text>ABN 97 610 838 643</Text>
        <Text>Ausrealty (Riverwood) Pty Ltd</Text> */}
      </View>
    </Page>
  </Document>
);

export default RentalAppraisalPDF;
