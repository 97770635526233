// import React, { useState } from "react";

// const EditableField = ({ value, onSave }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [tempValue, setTempValue] = useState(value);

//   const handleSave = () => {
//     setIsEditing(false);
//     onSave(tempValue);
//   };

//   return (
//     <span className="px-1">
//       {isEditing ? (
//         <input
//           type="text"
//           value={tempValue}
//           onChange={(e) => setTempValue(e.target.value)}
//           onBlur={handleSave}
//           onKeyPress={(e) => {
//             if (e.key === "Enter") {
//               handleSave();
//             }
//           }}
//           autoFocus
//           className="border border-mediumgray rounded px-2 py-1"
//         />
//       ) : (
//         <span>
//           {value}{" "}
//           <i
//             className="ml-2 text-sm fa-solid fa-pencil cursor-pointer"
//             onClick={() => setIsEditing(true)}
//           ></i>
//         </span>
//       )}
//     </span>
//   );
// };

// export default EditableField;

import React, { useState, useEffect, useRef } from "react";

const EditableField = ({ value, onSave, isCurrency, isHtml = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      adjustTextareaSize(); // Adjust the size on mount
    }
  }, [tempValue, isEditing]);

  const adjustTextareaSize = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset the height to auto to recalculate
      textarea.style.height = "auto";
      // Set height based on the scrollHeight
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleSave = () => {
    setIsEditing(false);

    if (tempValue === "" || tempValue === value) {
      setTempValue(value);
      return;
    }

    const finalValue = isCurrency
      ? parseFloat(tempValue.replace(/[^0-9.-]+/g, "")) // For currency fields
      : tempValue;

    onSave(finalValue);
  };

  // Helper function to remove HTML tags when switching to edit mode
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  return (
    <span className="w-auto">
      {isEditing ? (
        <textarea
          ref={textareaRef}
          value={isHtml ? stripHtmlTags(tempValue) : tempValue} // Remove HTML tags in edit mode
          onChange={(e) => {
            setTempValue(e.target.value);
            adjustTextareaSize(); // Dynamically adjust size on every change
          }}
          onBlur={handleSave}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSave();
            }
          }}
          autoFocus
          className="w-full border border-mediumgray form-input resize-none overflow-hidden text-sm"
          rows={1}
        />
      ) : (
        // Conditionally render either HTML or plain text
        isHtml ? (
          <span
            className="inline-block break-words w-auto px-2"
            dangerouslySetInnerHTML={{ __html: value }} // Render HTML if `isHtml` is true
          />
        ) : (
          <span className="inline-block break-words w-auto px-2">
            {value} {/* Render plain text if not HTML */}
          </span>
        )
      )}
      <i
        className="ml-2 text-sm fa-solid fa-pencil cursor-pointer"
        onClick={() => setIsEditing(true)}
      ></i>
    </span>
  );
};

export default EditableField;
