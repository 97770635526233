import React, { useState, useRef } from "react";
import Button from "components/ui/Button";
import SignatureCanvas from "react-signature-canvas";
import axios from "utils/axios";
import "assets/css/style.css";
import profile from "assets/images/profile (1).png";

const mobileRegex =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

const SignaturePad = ({ onSave }) => {
  const sigCanvas = useRef({});

  const clear = () => {
    sigCanvas.current.clear();
    onSave(null);
  };

  const save = () => {
    if (!sigCanvas.current.isEmpty()) {
      const trimmedCanvas = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      onSave(trimmedCanvas);
    }
  };

  return (
    <div className="mb-8">
      <p>Please Sign Below</p>
      <div className="border border-mediumgray overflow-hidden">
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{
            className:
              "w-full max-w-[400px] h-[180px] signature-canvas bg-white",
          }}
        />
      </div>
      <div className="flex justify-between mt-4">
        <Button onClick={clear} className="gray-button">
          Clear
        </Button>
        <Button onClick={save} className="black-button">
          Create
        </Button>
      </div>
    </div>
  );
};

const CompleteProfile = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    signature: null,
    image: null,
    mobile: "",
    company: "",
    title: "",
  });
  const [mobileError, setMobileError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({ ...prev, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageDelete = () => {
    setFormData((prev) => ({ ...prev, image: null }));
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleSaveSignature = (signature) => {
    setFormData((prev) => ({ ...prev, signature }));
  };

  const handleSubmit = async () => {
    let hasErrors = false;

    // Validate mobile number
    if (!formData.mobile) {
      setMobileError("Mobile number is required");
      hasErrors = true; // Set hasErrors to true if the mobile number is missing
    } else if (!mobileRegex.test(formData.mobile)) {
      setMobileError("Mobile number is not valid");
      hasErrors = true; // Set hasErrors to true if the mobile number is invalid
    } else {
      setMobileError(""); // Clear the error if the mobile number is valid
    }

    // Validate company selection
    if (!formData.company) {
      setCompanyError("Company is required");
      hasErrors = true;
    } else {
      setCompanyError("");
    }

    // Validate title selection
    if (!formData.title) {
      setTitleError("Title is required");
      hasErrors = true;
    } else {
      setTitleError("");
    }

    // If there are no errors, proceed with the form submission
    if (!hasErrors) {
      try {
        setLoading(true);
        const { data: presignedData } = await axios.get(
          "/profile/generate-presigned-url"
        );

        const response = await fetch(formData.signature);
        const blob = await response.blob();

        await fetch(presignedData.uploadURL, {
          method: "PUT",
          body: blob,
          headers: {
            "Content-Type": "image/png",
          },
        });

        const saveResponse = await axios.post("/profile/complete-profile", {
          mobile: formData.mobile,
          company: formData.company,
          title: formData.title,
          s3Key: presignedData.key,
          image: formData.image,
        });

        if (saveResponse.data.success) {
          window.location.href = "/";
        }
      } catch (error) {
        console.error("Error saving profile:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMobileChange = (e) => {
    const mobile = e.target.value;
    setFormData((prev) => ({ ...prev, mobile }));

    if (!mobileRegex.test(mobile)) {
      setMobileError("Mobile number is not valid");
    } else {
      setMobileError("");
    }
  };

  const handleCompanyChange = (e) => {
    const company = e.target.value;
    setFormData((prev) => ({ ...prev, company }));

    if (!company) {
      setCompanyError("Company is required");
    } else {
      setCompanyError("");
    }
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;
    setFormData((prev) => ({ ...prev, title }));

    if (!title) {
      setTitleError("Title is required");
    } else {
      setTitleError("");
    }
  };

  const handleNext = () => {
    if (validateCurrentStep()) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const validateCurrentStep = () => {
    if (currentStep === 1 && !formData.signature) return false;
    if (currentStep === 2 && !formData.image) return false;
    if (
      currentStep === 3 &&
      (!formData.mobile || mobileError || !formData.company || !formData.title)
    )
      return false;
    return true;
  };

  return (
    <div className="max-w-lg mx-auto px-6 py-8">
      <h4 className="text-center">Please complete your Profile to continue</h4>
      <div className="flex justify-between items-center my-8">
        <StepCircle
          step={1}
          currentStep={currentStep}
          icon={<i className="fa-solid fa-file-signature"></i>}
          text="Sign"
        />
        <StepLine currentStep={currentStep} step={2} />
        <StepCircle
          step={2}
          currentStep={currentStep}
          icon={<i className="fa-solid fa-user"></i>}
          text="Image"
        />
        <StepLine currentStep={currentStep} step={3} />
        <StepCircle
          step={3}
          currentStep={currentStep}
          icon={<i className="fa-solid fa-file"></i>}
          text="Details"
        />
      </div>

      {currentStep === 1 && (
        <>
          <SignaturePad onSave={handleSaveSignature} />
          {formData.signature && (
            <div className="mt-8 text-start">
              <p>Your Signature</p>
              <div className="flex gap-4 items-end">
                <img
                  src={formData.signature}
                  alt="Your Signature"
                  className="w-auto p-2 h-16 object-fill border border-mediumgray"
                />
              </div>
            </div>
          )}
        </>
      )}

      {currentStep === 2 && (
        <>
          <div className="text-center">
            {formData.image ? (
              <div className="relative">
                <img
                  src={formData.image}
                  alt="Uploaded"
                  className="w-full h-auto max-w-[250px] max-h-[250px] mx-auto object-cover"
                />
                <button className="mt-4 underline" onClick={handleImageDelete}>
                  Delete
                </button>
              </div>
            ) : (
              <div
                className="w-full h-auto max-w-[250px] max-h-[250px] mx-auto flex items-center justify-center cursor-pointer"
                onClick={handleImageClick}
              >
                <img src={profile}></img>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>
        </>
      )}

      {currentStep === 3 && (
        <div className="grid gap-4">
          <div className="col-span-12 relative">
            <label className="form-label text-start">Mobile</label>
            <input
              type="text"
              className={`form-input border border-mediumgray ${
                mobileError ? "border-red-500" : ""
              }`}
              value={formData.mobile}
              onChange={handleMobileChange}
              placeholder="Enter mobile"
            />
            {mobileError && (
              <p className="text-red-500 text-sm">{mobileError}</p>
            )}
          </div>

          <div className="col-span-12 relative">
            <label className="form-label">Company</label>
            <select
              className={`form-select border ${
                companyError ? "border-red-500" : "border-mediumgray"
              }`}
              value={formData.company}
              onChange={handleCompanyChange}
            >
              <option value="">Select Company</option>
              {[
                "Ausrealty (Riverwood) Pty Ltd (Licensed user of Ausrealty)",
                "KK Property Services Pty Ltd (Licensed user of Ausrealty)",
                "I.M Group Pty Ltd (Licenced user of Ausrealty)",
                "MRL Property Group Pty Ltd (Licenced user of Ausrealty)",
                "Anodos Enterprises Pty Ltd (Licenced user of Ausrealty)",
                "I Sayed Investments Pty Ltd (Licenced user of Ausrealty)",
                "Suti Investments Pty Ltd (Licenced user of Ausrealty)",
                "Hani Property Services Pty Ltd (Licenced user of Ausrealty)",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {companyError && (
              <span className="form-error-message text-red-500 text-sm">
                {companyError}
              </span>
            )}
          </div>

          <div className="col-span-12 relative">
            <label className="form-label">Title</label>
            <select
              className={`form-select border ${
                titleError ? "border-red-500" : "border-mediumgray"
              }`}
              value={formData.title}
              onChange={handleTitleChange}
            >
              <option value="">Select Title</option>
              {[
                "Partner",
                "Director",
                "Associate Agent",
                "Residential Sales Agent",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {titleError && (
              <span className="form-error-message text-red-500 text-sm">
                {titleError}
              </span>
            )}
          </div>
        </div>
      )}

      <div className="flex justify-between mt-6">
        {currentStep > 1 && (
          <Button onClick={handlePrevious} className="gray-button">
            Previous
          </Button>
        )}
        {currentStep < 3 && formData.signature && (
          <Button onClick={handleNext} className="ml-auto black-button">
            Next
          </Button>
        )}
        {currentStep === 3 && (
          <Button
            type="submit"
            onClick={handleSubmit}
            className="ml-auto black-button"
            disabled={loading}
            loading={loading}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

const StepCircle = ({ step, currentStep, icon, text }) => {
  const isActive = step <= currentStep;
  return (
    <div className="flex flex-col items-center">
      <div
        className={`w-12 h-12 flex items-center justify-center rounded-full border ${
          isActive
            ? "border-black bg-black text-white"
            : "border-mediumgray bg-white text-darkgray"
        }`}
      >
        {icon}
      </div>
      <span
        className={`mt-2 text-sm ${isActive ? "text-black" : "text-darkgray"}`}
      >
        {text}
      </span>
    </div>
  );
};

const StepLine = ({ currentStep, step }) => {
  const isActive = step <= currentStep;
  return (
    <div
      className={`transform -translate-y-4 flex-grow h-0.5 ${
        isActive ? "bg-black" : "bg-mediumgray"
      }`}
    ></div>
  );
};

export default CompleteProfile;
