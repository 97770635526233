import React, { useState } from "react";
import Modal from "components/ui/Modal"; // Assuming Modal component is in the same directory
import Button from "components/ui/Button"; // Assuming Button component is in the same directory

const AuthoriseSchedule = () => {
  const [isFirstImageClicked, setIsFirstImageClicked] = useState(false);
  const [sendWhatsappButtonClick, setSendWhatsappButtonClick] = useState(false);

  const sendToWhatsApp = () => {
    setSendWhatsappButtonClick(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    // <div className="w-full h-full max-w-4xl mx-auto text-center flex flex-col justify-between">
    <div className="w-full h-full max-w-4xl mx-auto text-center flex flex-col justify-center items-center pt-32">
      <p>Exciting feature to be released soon</p>
    </div>
  );
};

export default AuthoriseSchedule;
